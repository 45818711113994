import React from "react"
import styled from "styled-components"

import useSubscription from "../../states/context/SubscriptionContext"
import Helpers from "../../utils/Helpers"
import Gtag from "../../utils/classes/Gtag"

import AccountDownloadButton from "../buttons/AccountDownloadButton"
import PurchaseButton from "../buttons/PurchaseButton"
import { LogoContainer } from "../cards/AccountDownloadCard"
import * as TextStyles from "../styles/TextStyles"

const AccountDownloadPrevious = () => {
  const { subscriptionStatus } = useSubscription()

  const accessEndDate =
    subscriptionStatus?.accessEndDate &&
    Helpers.formatToLocaleDateString(subscriptionStatus?.accessEndDate)

  return (
    <Wrapper>
      <Title>Angle 3</Title>
      <Description>
        Your Angle 3 free upgrades ended on {accessEndDate}. You can still download the Angle 3
        mockups.
      </Description>
      <LogoContainer>
        <AccountDownloadButton
          logo="/images/logos/glass-sketch.png"
          color="linear-gradient(16.46deg, #DA732C 31.92%, #F6D24D 88.28%)"
          title="Sketch"
          downloadType="proSketch"
        />
        <AccountDownloadButton
          logo="/images/logos/glass-figma.png"
          color="linear-gradient(192.92deg, #727272 9.97%, #333333 82.89%)"
          title="Figma"
          downloadType="proFigma"
        />
        <AccountDownloadButton
          logo="/images/logos/glass-xd.png"
          color="linear-gradient(338.86deg, #DB5EB8 14.4%, #F8BFEE 85.48%)"
          title="XD"
          downloadType="proXD"
        />
      </LogoContainer>
      {/* <PurchaseButton icon="/images/icons/downloads-pink.svg" title="Download here" /> */}
      {/* <Description>Available for download: YYYY-MM-DD</Description> */}
    </Wrapper>
  )
}

export default AccountDownloadPrevious

const Wrapper = styled.div`
  display: grid;
  gap: 40px;
  text-align: center;
  padding: 0 20px;

  .purchase {
    margin: 0 auto;
  }
`

const Title = styled(TextStyles.H2)``

const Description = styled(TextStyles.MediumText)`
  max-width: 420px;
  margin: 0 auto;
`
