import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"

import useAuth from "../../states/context/AuthContext"
import useSubscription from "../../states/context/SubscriptionContext"
import Helpers from "../../utils/Helpers"
import Gtag from "../../utils/classes/Gtag"

import AccountDownloadButton from "../buttons/AccountDownloadButton"
import PillButton from "../buttons/PillButton"
import * as TextStyles from "../styles/TextStyles"

const AccountDownloadCard = () => {
  const { isUserLoggedIn } = useAuth()
  const { isProUser, isFreeUser, subscriptionStatus, libraries, renewSubscription } =
    useSubscription()

  const isFormerProUser =
    subscriptionStatus?.subscriptionCase === "subscriptionCancelled" ||
    subscriptionStatus?.subscriptionCase === "purchaseExpired"
  const accessEndDate =
    subscriptionStatus?.accessEndDate &&
    Helpers.formatToLocaleDateString(subscriptionStatus?.accessEndDate)
  const planName = subscriptionStatus?.subscription?.planName
  const lastUpdatedDate = libraries.pro?.["figma"]?.lastUpdated
  const lastUpdatedDateFormatted =
    lastUpdatedDate && Helpers.formatToLocaleDateString(lastUpdatedDate)

  const renew = () => {
    Gtag.sendEvent("upgrade")
    renewSubscription()
    navigate("/pricing")
  }

  return (
    <Wrapper>
      {isUserLoggedIn ? (
        <>
          <Title>Angle 4</Title>
          <Description>
            {isProUser ? (
              <>
                {planName === "Lifetime" && `You have unlimited upgrades to the newest mockups.`}
                {planName === "1 Year" &&
                  `You have one year of free upgrades to the newest mockups until ${accessEndDate}.`}
              </>
            ) : isFormerProUser ? (
              <>
                Your purchase has expired since {accessEndDate}, you may only download the free
                samples. If you wish to renew, we offer 40% off for Angle 2 & 3 users. Activate your
                discount here.
              </>
            ) : (
              <>
                You are under a free account, you may only download the free samples. To download
                the full library, please upgrade in the{" "}
                <Link to="/pricing" style={{ textDecoration: "none" }}>
                  pricing page
                </Link>
                .
              </>
            )}
          </Description>
          {isFreeUser && isFormerProUser && (
            <PillButton title="Upgrade" icon="/images/icons/arrow-up-circle.svg" onClick={renew} />
          )}
        </>
      ) : (
        <>
          <Title2>Sign In Required</Title2>
          <Description>Please sign in to access your account.</Description>
        </>
      )}
      {isProUser && (
        <>
          <LogoContainer>
            <AccountDownloadButton
              logo="/images/logos/glass-sketch.png"
              color="linear-gradient(16.46deg, #DA732C 31.92%, #F6D24D 88.28%)"
              title="Sketch"
              downloadType="proSketch"
            />
            <AccountDownloadButton
              logo="/images/logos/glass-figma.png"
              color="linear-gradient(192.92deg, #727272 9.97%, #333333 82.89%)"
              title="Figma"
              downloadType="proFigma"
            />
            <AccountDownloadButton
              logo="/images/logos/glass-xd.png"
              color="linear-gradient(338.86deg, #DB5EB8 14.4%, #F8BFEE 85.48%)"
              title="XD"
              downloadType="proXD"
            />
          </LogoContainer>
          <Description>Last updated on {lastUpdatedDateFormatted}</Description>
        </>
      )}
    </Wrapper>
  )
}

export default AccountDownloadCard

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  max-width: 725px;
  width: 100%;
  margin: -30% auto 0;
  padding: 80px 20px 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(60px);
  border-radius: 35px;
`

const Title = styled(TextStyles.H2)`
  color: #6234d5;
`

const Title2 = styled(TextStyles.H4)`
  color: #6234d5;
`

const Description = styled(TextStyles.MediumText)`
  color: rgba(0, 0, 0, 0.7);
  max-width: 340px;
  margin: 0 auto;
`

export const LogoContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: 80px;
  padding-top: 55px;

  @media (max-width: 620px) {
    grid-template-columns: auto;
  }
`
