import { navigate } from "gatsby"

import useAuth from "../../states/context/AuthContext"
import useSubscription from "../../states/context/SubscriptionContext"
import { useShowModal } from "../../states/context/ModalContext"
import { DownloadType } from "../../types/downloadTypes"
import Gtag from "../classes/Gtag"

const downloadOption = {
  freeSample: {
    title: "Free Sample",
    buttonTitle: "Download Sample",
    item: "sample",
  },
  freePlugin: {
    title: "Free Plugin",
    buttonTitle: "Download Plugin",
    item: "plugin",
  },
  freeWallpaper: {
    title: "Free Wallpaper",
    buttonTitle: "Download Wallpapers",
    item: "wallpaper",
  },
  proSketch: {
    title: "Pro Sketch",
    buttonTitle: "Download for Sketch",
    item: "sketch",
  },
  proFigma: {
    title: "Pro Figma",
    buttonTitle: "Download for Figma",
    item: "figma",
  },
  proXD: {
    title: "Pro XD",
    buttonTitle: "Download for XD",
    item: "xd",
  },
  proWallpaper: {
    title: "Pro Wallpaper",
    buttonTitle: "Download Wallpapers",
    item: "wallpaper",
  },
}

const useDownload = (downloadType: DownloadType = "freeSample", fromAccount: boolean = false) => {
  const { isUserLoggedIn } = useAuth()
  const { isProUser, isFormerProUser, libraries } = useSubscription()
  const showSignUpModal = useShowModal("signUp")
  const showSignInModal = useShowModal("signIn")

  const isProType = downloadType.startsWith("pro") ? true : false

  const library =
    isFormerProUser && isProType && fromAccount
      ? libraries.pro ?? libraries.free
      : isProUser && isProType
      ? libraries.pro ?? libraries.free
      : libraries.free
  // const libraryType =
  //   isFormerProUser && isProType && fromAccount
  //     ? "pro" ?? "free"
  //     : isProUser && isProType
  //     ? "pro" ?? "free"
  //     : "free"
  const downloadItem = library[downloadOption[downloadType]?.item]
  let downloadItemType: DownloadType

  if (!downloadItem) {
    downloadItemType = "freeSample"
  } else {
    const str = downloadItem?.title.replace(/[^a-zA-Z]/g, "")
    downloadItemType = (str.charAt(0).toLowerCase() + str.slice(1)) as DownloadType
  }
  const buttonTitle = downloadOption[downloadItemType].buttonTitle

  const download = () => {
    if (isUserLoggedIn) {
      if (isProUser) {
        openDownloadLink()
      } else if (isFormerProUser && fromAccount) {
        openDownloadLink()
      } else {
        if (!isProType) openDownloadLink()
        if (isProType) navigate("/pricing")
      }
    } else {
      fromAccount ? showSignInModal() : showSignUpModal()
    }
  }

  const openDownloadLink = () => {
    Gtag.sendEvent(`download ${downloadItem.title}`, "downloads", downloadItem.link, 1)
    window.open(downloadItem.link, "_blank")
  }

  return { download, buttonTitle }
}

export default useDownload
