import { DesignTool } from "../components/buttons/DiscountButton"

export const discounts = [
  {
    title: "Design+Code 4",
    logo: "/images/logos/discounts/dc4.png",
    text: "50% off",
    link: "https://designcode.io/?promo=j9ts3u",
  },
  // {
  //   title: "Sketch Pro",
  //   designTool: "Sketch",
  //   logo: "/images/logos/discounts/sketch.png",
  //   text: "20% off",
  //   link: "https://www.sketchapp.com/pricing",
  // },
  // {
  //   title: "Figma Pro",
  //   designTool: "Sketch",
  //   logo: "/images/logos/discounts/figma.png",
  //   text: "3 months free",
  //   link: "https://www.figma.com/pricing",
  // },
  {
    title: "Shape",
    logo: "/images/logos/discounts/shape.png",
    text: "50% off",
    link: "https://shape.so/?promo=designcode",
  },
  {
    title: "Invision Pro",
    logo: "/images/logos/discounts/invision.png",
    text: "6 months free",
    designTool: DesignTool.inVision,
  },
  {
    title: "Framer Pro",
    logo: "/images/logos/discounts/framer.png",
    text: "20% off",
    designTool: DesignTool.framerX,
  },
  {
    title: "ProtoPie",
    logo: "/images/logos/discounts/protopie.png",
    text: "20% off",
    designTool: DesignTool.protopie,
  },
]
