import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"
import DiscountButton from "../buttons/DiscountButton"
import { discounts } from "../../data/DiscountData"

const DiscountSection = () => {
  return (
    <Wrapper>
      <Container>
        <Title>Discounts</Title>
        <Description>
          As a member of the Angle family, you're eligible for some great discouts!
        </Description>
        <ButtonContainer>
          {discounts.map((discount, index) => (
            <DiscountButton key={index} {...discount} />
          ))}
        </ButtonContainer>
      </Container>
    </Wrapper>
  )
}

export default DiscountSection

const Wrapper = styled.div`
  text-align: center;
`

const Container = styled.div`
  max-width: 1234px;
  margin: 0 auto;
`

const Title = styled(TextStyles.H2)``

const Description = styled(TextStyles.MediumText)`
  max-width: 340px;
  margin: 0 auto;
  padding-top: 20px;
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 50px;
  margin: 0 auto;
  padding: 100px 0px 200px;

  @media (max-width: 490px) {
    grid-template-columns: 1fr;
  }
`
