declare global {
  interface Window {
    gtag: any
  }
}
// Move to globals.d.ts if adding other global variables

class Gtag {
  static sendEvent(action: string, category?: string, label?: string, value?: number) {
    if (label) label = label.toLowerCase()

    if (action && category) {
      typeof window !== "undefined" &&
        window.gtag &&
        window.gtag("event", action.toLowerCase(), {
          event_category: category.toLowerCase(),
          event_label: label,
          value: value,
        })
    }
  }

  static sendPageview(path: string, title: string) {
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag("event", "page_view", {
        page_path: path,
        page_title: title,
      })
  }

  // static sendPageview(title: string, location: string, path: string) {
  //   gtag("config", config.GA_TRACKING_ID, {
  //     page_title: title,
  //     page_location: location,
  //     page_path: path,
  //   })
  // }

  static getClientId() {
    const cookie = document?.cookie?.match("(^|;) *_ga=([^;]*)")?.[2]
    const parts = cookie?.split(".") // e.g. GA1.1.429550030.1583438718

    if (parts) {
      return parts[2] + "." + parts[3] // e.g. 429550030.1583438718
    }
  }

  static setUserId(UserId: string) {
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag("set", {
        user_id: UserId,
      })
  }
}

export default Gtag

// https://support.google.com/analytics/answer/9964640?hl=en#zippy=%2Cin-this-article
