import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import useAuth from "../../states/context/AuthContext"
import { useShowModal } from "../../states/context/ModalContext"

import * as TextStyles from "../styles/TextStyles"
import FormInput from "../inputs/FormInput"
import FormButton from "../buttons/FormButton"

const AccountSettings = () => {
  const { currentUser, signOut } = useAuth()
  const showResetPasswordModal = useShowModal("resetPassword")

  return (
    <Wrapper>
      <Container>
        <StaticImage
          className="illustration"
          src="../../images/illustrations/illustration1.webp"
          alt="illustration"
        />
        <Title>Account Settings</Title>
        <Caption>Update your account password</Caption>
      </Container>
      <Form>
        <SubtitleContainer>
          <Subtitle>Email Address</Subtitle>
          <UpdatePasswordButton onClick={showResetPasswordModal}>
            Update Password
          </UpdatePasswordButton>
        </SubtitleContainer>
        <FormInput
          name="email"
          type="email"
          placeholder={currentUser?.email}
          value={currentUser?.email}
          icon="/images/icons/password.svg"
          disabled
        />
        <FormButton title="Sign out" type="button" onClick={signOut} />
      </Form>
    </Wrapper>
  )
}

export default AccountSettings

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  margin: 200px 0px;

  .illustration {
    max-width: 210px;
  }

  .button {
    justify-self: start;
  }
`

const Form = styled.form`
  button {
    margin: 30px 0 0 0;
    width: fit-content;
  }
`

const Container = styled.div`
  display: grid;
  gap: 10px;
  text-align: center;
`

const Title = styled(TextStyles.H3)``

const Caption = styled(TextStyles.MediumText)`
  max-width: 340px;
  margin: 0 auto;
`

const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px 10px;
`

const Subtitle = styled(TextStyles.H5)``

const UpdatePasswordButton = styled(TextStyles.Caption)`
  color: #8338a8;
  cursor: pointer;
  font-weight: normal;
`
