import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import useAuth from "../states/context/AuthContext"
import useSubscription from "../states/context/SubscriptionContext"
import { useShowModal } from "../states/context/ModalContext"
import { MetadataReadonly } from "../types/contentfulTypes"

import Layout from "../components/layout/Layout"
import AccountDownloadCard from "../components/cards/AccountDownloadCard"
import AccountDownloadPrevious from "../components/sections/AccountDownloadPrevious"
import AccountSettings from "../components/sections/AccountSettings"
import AccountDiscounts from "../components/sections/AccountDiscounts"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Account = (props: Props) => {
  const { data } = props

  const { isAuthLoading, isUserLoggedIn } = useAuth()
  const { isProUser, isFormerProUser } = useSubscription()
  const showSignInModal = useShowModal("signIn")

  useEffect(() => {
    if (!isAuthLoading && !isUserLoggedIn) showSignInModal()
  }, [isAuthLoading])

  return (
    <Layout {...data}>
      <Wrapper>
        <HeroContainer>
          <StaticImage
            className="illustration"
            src="../images/illustrations/illustration9.webp"
            alt="illustration"
          />
          <AccountDownloadCard />
        </HeroContainer>
        {isUserLoggedIn && (
          <>
            {isFormerProUser && <AccountDownloadPrevious />}
            <AccountSettings />
            {isProUser && <AccountDiscounts />}
          </>
        )}
      </Wrapper>
    </Layout>
  )
}

export default Account

export const query = graphql`
  query AccountQuery {
    contentfulMetadata(slug: { eq: "account" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  display: grid;
  gap: 200px;
  padding: 0px 20px;

  .illustration {
    max-width: 820px;
    margin: 0 auto;
  }
`

const HeroContainer = styled.div`
  width: 100%;
  max-width: 1234px;
  text-align: center;
  margin: 0 auto;
`
