import React from "react"
import styled from "styled-components"

import useDownload from "../../utils/hooks/useDownload"
import { DownloadType } from "../../types/downloadTypes"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  logo: string
  title: string
  color: string
  downloadType: DownloadType
}

const AccountDownloadButton = (props: Props) => {
  const { logo, title, color, downloadType } = props

  const { download } = useDownload(downloadType, true)

  return (
    <Wrapper onClick={download} color={color}>
      <Logo className="logo" src={logo} alt={`${title} logo`} />
      <Title className="title">Download for {title}</Title>
    </Wrapper>
  )
}

export default AccountDownloadButton

const Wrapper = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${props =>
    props.color || "linear-gradient(16.46deg, #da732c 31.92%, #f6d24d 88.28%)"};
  cursor: pointer;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.1) translateY(-10px);
  }
`

const Logo = styled.img`
  position: absolute;
  width: 69px;
  height: auto;
  margin: -25px auto 0;
  left: 0;
  right: 0;
`

const Title = styled(TextStyles.SmallText)`
  position: absolute;
  max-width: 90px;
  color: #ffffff;
  font-weight: bold;
  margin: auto;
  top: 45px;
  left: 0;
  right: 0;
`
