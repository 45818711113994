import React, { useState, useEffect } from "react"
import styled from "styled-components"

import useAuth from "../../states/context/AuthContext"
import { useShowModal } from "../../states/context/ModalContext"
import useFetchFunctions, { FunctionsEndpoint } from "../../utils/hooks/useFetchFunctions"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  title: string
  logo: string
  text: string
  link?: string | undefined
  designTool?: DesignTool | undefined
}

export enum DesignTool {
  framerX = "Framer X",
  inVision = "InVision Pro",
  protopie = "ProtoPie",
}

const DiscountButton = (props: Props) => {
  const { title, logo, text, link, designTool } = props
  const [error, setError] = useState(false)
  const [code, setCode] = useState("")
  const { currentUser } = useAuth()
  const { fetchFunctions } = useFetchFunctions()
  const showErrorModal = useShowModal("message", {
    logLevel: "error",
    message: "An error occured. Please try again later or contact support at angle@designcode.io.",
  })

  useEffect(() => {
    error && showErrorModal()
  }, [error])

  const fetchCode = async () => {
    if (code != "") return
    setError(false)

    const response = await fetchFunctions(FunctionsEndpoint.getDiscountCode, {
      currentUser,
      designTool,
    })

    if (response.error) {
      setError(true)
      return
    }

    setCode(response.payload.code)
  }

  return (
    <Wrapper>
      {link && (
        <a href={link} rel="noopener noreferrer" target="_blank">
          <Logo src={logo} alt="logo" />
        </a>
      )}

      {designTool && <Logo src={logo} alt="logo" onClick={fetchCode} />}
      <Text>{code != "" ? code : text}</Text>
      <Title>{title}</Title>
    </Wrapper>
  )
}

export default DiscountButton

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
`

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin: 0 auto;
  cursor: pointer;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.1);
  }
`

const Text = styled(TextStyles.H4)`
  padding-top: 30px;
`

const Title = styled(TextStyles.H5)`
  padding-top: 16px;
`
